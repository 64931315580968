<template>
  <div>
    <ListaCliente />
  </div>
</template>

<script>
import ListaCliente from '@/components/Cliente/ListaCliente'
export default {
  components: {ListaCliente},
  data(){
    return{}
  }
}
</script>

<style>

</style>