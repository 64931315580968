<template>
  <v-container>
    <div class="header">
      <span>
        <h3>Clientes</h3>
        <p>Listagem de clientes</p>
      </span>
      <div class="grow"/>
      <button type="button" class="btn"  v-if="loading"  @click="$router.push('/criar/cliente')">
        <v-icon color="#f9f9f2" size='28'>mdi-plus-circle-outline</v-icon>
      </button>
    </div>
    <div class="containerFiltros">
      <div style="width:100%;">
             <v-container class="">
                <v-row>
                  <v-col cols="12" md="3" style="">
                      <v-text-field hide-details type="" label="Digite um nome" style="margin-bottom:0px;padding-bottom:0px!important;" v-model="inputNome" @keyup="filtroPesquisaNome" outlined ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <!-- {{sistemaSelecionado}} -->
                      <v-select return-object hide-details  :items="sistemas" item-text="nome" item-value="value" label="Filtrar por sistema" 
                      v-model="sistemaSelecionado" outlined >
                      </v-select>
                  </v-col>
                  <v-col cols="12" md="2">
                      <v-select hide-details  :items="status" item-text="nome" item-value="value" label="Status" v-model="statusSelecionado" return-object outlined>
                      </v-select>
                  </v-col>
                  <v-col cols="12" md="2">
                      <v-select hide-details  :items="pausados" item-text="nome" item-value="value" label="Pausado?" v-model="pausadoSelecionado" return-object outlined>
                      </v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                      <v-select hide-details  :items="tipointegracao" label="Tipo Integração" item-text="nome" item-value="value" v-model="tipointegracaoSelecionado" return-object outlined>
                      </v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                      <v-select hide-details  :items="listateste" label="Cliente de Teste?" item-text="nome" item-value="value" v-model="tipoteste" return-object outlined>
                      </v-select>
                  </v-col>
                </v-row>
              </v-container>
        
      </div>
      <div class="containerFiltroSistema">
          <button type="button" class="btnAddFiltro" @click="requisitarClientes">Pesquisar</button>
          <button type="button" class="btnLimarFiltro" @click="limparFiltroSistema">Limpar filtro</button>
          <JsonCSV :data="items" v-if="items.length > 0" name="Clientes.csv">
              <div style="background-color:green;width:150px;height:30px;margin-left:10px;display:flex;align-items:center;justify-content:center;font-weight:bold;color:white;border-radius:5px;">
                  Download 
              </div>
          </JsonCSV>
      </div>
    </div>
    <section class="wrapper-list" v-if="loading">
      <!-- lista de usuarios -->
      <v-data-table 
        :items="items"
        :headers="headers"
        :items-per-page="50"
        >


        <template v-slot:[`item.urlficha`]="{ item }">
          <a :href="item.urlficha" title="URL API" target="_blank">URL</a>
        </template>

        <template v-slot:[`item.nome`]="{ item }">
            <a @click="() => abrirEdicao(item)" title="URL API" >{{item.nome}}</a>
        </template>


        <!-- <template v-slot:[`item.contato1`]="{ item }">
          {{item.contato1+ ' : '+item.telefonecontato1}}
        </template>
        
        <template v-slot:[`item.contato2`]="{ item }">
          {{item.contato2+ ' : '+item.telefonecontato2}}
        </template> -->

        <template v-slot:[`item.clientepausado`]="{ item }">
          {{item.clientepausado ? 'Pausado' : '-'}}
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <div style="display:flex;flex-direction:rows;align-items:space-between;justify-content:space-between;width:150px;">

            <!-- 
            <v-btn  small class="ml-2" style="margin: 0 0 0 0" @click="() => setModalParametros(item)">
              <v-icon style="margin: 0 0 0 0" color="green" title="Editar">
                mdi-cog
              </v-icon>
            </v-btn>
            <v-btn  small class="ml-2" style="margin: 0 0 0 0" @click="setModalMovimentos(item)" title="Movimentos">
              <v-icon style="margin: 0 0 0 0" color="green" title="Movimentos">
                mdi-format-list-bulleted-square
              </v-icon>
            </v-btn>
            <v-btn  small class="ml-2" style="margin: 0 0 0 0" @click="setModalDesativar(item)" title="Desativar">
              <v-icon style="margin: 0 0 0 0" color="red" title="Desativar">
                mdi-close
              </v-icon>
            </v-btn>
            <v-btn  small class="ml-2" style="margin: 0 0 0 0" @click="setModalPausar(item)" title="Desativar">
              <v-icon style="margin: 0 0 0 0" color="red" title="Desativar">
                mdi-stop
              </v-icon>
            </v-btn>
            <v-btn  small class="ml-2" style="margin: 0 0 0 0; width:5px!important;" @click="setModalPausar(item)" title="Desativar">
              <v-icon style="margin: 0 0 0 0;width:5px!important;height:5px!important;" color="blue" title="Desativar">
                mdi-robot
              </v-icon>
            </v-btn> -->
            <button @click="() => setModalParametros(item)">
              <v-icon style="margin: 0 0 0 0;width:5px!important;height:5px!important;" color="green" title="Desativar">
                mdi-cog
              </v-icon>
            </button>
            <button  @click="setModalMovimentos(item)">
              <v-icon style="margin: 0 0 0 0;width:5px!important;height:5px!important;" color="green" title="Desativar">
                mdi-format-list-bulleted-square
              </v-icon>
            </button>
            <button @click="setModalDesativar(item)">
              <v-icon style="margin: 0 0 0 0;width:5px!important;height:5px!important;" color="red" title="Desativar">
                mdi-close
              </v-icon>
            </button>
            <button @click="setModalPausar(item)">
              <v-icon style="margin: 0 0 0 0;width:5px!important;height:5px!important;" color="red" title="Desativar">
                mdi-stop
              </v-icon>
            </button>
            <button @click="setModalRobo(item)">
              <v-icon style="margin: 0 0 0 0;width:5px!important;height:5px!important;" color="blue" title="Desativar">
                mdi-robot
              </v-icon>
            </button>
          </div>
        </template>
      </v-data-table>
    </section>
    <ListSkeleton  v-if="!loading"/>

    <v-dialog v-model="exibirModalMovimento" width="80vw" style="max-height:90vh;height:90vw;"  >

                    <div style="display:flex;flex-direction:row;width:100%;max-height:80vh;height:80vw;">
                    
                
                            <div style="background-color:white;width:40%;display:flex;flex-direction:column;border-right:1px solid #b1b1b1;max-height:80vh;height:80vw;">

                                <div>
                                    <v-container class="pa-8" style="padding-top:1px!important;">
                                        <v-row>

                                            <v-col cols="12" md="12">Novo Movimento
                                                    <v-container class="">
                                                    <v-row>
                                                        <v-col  cols="12" md="12" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;" >
                                                            <v-textarea height="50" outlined color="" hide-details placeholder="" error-count="" name="" label="" v-model="movimento.descricao" style="margin:0px!important;padding:0px!important;" ></v-textarea> 
                                                        </v-col>
                                                    </v-row>
                                                    <v-row style="margin-top:20px;">
                                                        <v-col  cols="12" md="4" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;" >
                                                            <v-text-field type="number" filled label="Horas" v-model="movimento.tempo_gasto" name="texto" hide-details ></v-text-field>
                                                        </v-col>
                                                        <v-col  cols="12" md="8" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;display:flex;justify-content:flex-end;margin-top:20px;" >
                                                            <v-btn color="info" @click="registrarMovimento(3)"  hide-details>Movimentar</v-btn >
                                                        </v-col>
                                                    </v-row>
                                                  
                                                   
                                                    </v-container>
                                                        
                                            </v-col>
                                            
                                            
                                        
                                        

                                        </v-row>
                                    </v-container>
                                </div>
                                
                            </div>

                            <div style="background-color:#efefef;width:60%;display:flex;flex-direction:column;padding:10px;max-height:80vh;height:80vw;overflow:auto;padding-bottom:20px!important;">

                                <v-container class="pa-2" style="max-height:80vh;padding-bottom:20px!important;">
                                    <v-card class="" style="margin-bottom:10px;" elevation="" max-width="" v-for="item in movimentos" :key="item.id" :dot-color="item.color" >
                                        <v-card-title style="font-size:16px;">{{item.desctipomovimento}} - {{item.tiporegistro}}</v-card-title>
                                        <v-card-text style="font-size:16px;">
                                            <div><b>Descrição:</b> {{item.descricao}}</div>
                                            <div><b>Horas Gastas:</b> {{item.tempo_gasto}}</div>
                                        </v-card-text>
                                        <v-card-text style="display:flex;flex-direction:column;justify-content:flex-end;align-items:flex-end;font-weight:600;color:#817373;">
                                            <div>{{item.datamovimento}} as {{item.horamovimento}}</div> 
                                            <div>{{item.nomeusuario}}</div> 
                                        </v-card-text>
                                    
                                    </v-card>
                                </v-container>

                            </div>

                    </div>

    </v-dialog>

    <v-dialog v-model="exibirModalDesativar" width="50vw" style="max-height:90vh;height:90vw;padding:50px;"  >

                    <div style="display:flex;flex-direction:row;width:100%;max-height:80vh;height:80vw;">
                    
                
                            <div style="padding:50px;background-color:white;width:100%;display:flex;flex-direction:column;border-right:1px solid #b1b1b1;max-height:80vh;height:80vw;">

                                <div>
                                    <v-container class="pa-8" style="padding-top:1px!important;">
                                      <!-- {{clienteselecionado}}  -->
                                        <p style="font-size:30px;">Usuario Logado: <b> {{usuariologado}}</b></p>
                                        <p style="font-size:30px;"><span style="color:red;"><b>Desativar</b></span> {{clienteselecionado.nomesistemas}} do <b>{{clienteselecionado.nome}}</b></p>
                                        <p style="font-size:18px;color:red"><b>Observação</b>: Desativar uma implantaçaõ irá interromper o serviço do cliente imediatamente</p>
                                        <v-row>

                                            <v-col style="margin-top:20px;" cols="12" md="12">O serviço do <b>{{clienteselecionado.nomesistemas}}</b> já foi removido do PM2?
                                                    <v-container class="">
                                                      <v-row>
                                                          <v-col  cols="12" md="12" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;" >
                                                              <v-textarea height="50" outlined color="" hide-details placeholder="" error-count="" name="" label="" v-model="removeudoservidor" style="margin:0px!important;padding:0px!important;" ></v-textarea> 
                                                          </v-col>
                                                      </v-row>
                                                    </v-container>
                                                        
                                            </v-col>


                                            <v-col cols="12" md="12">Registre a solicitação de desativação aqui (Email, whatsapp, solicitação)
                                                    <v-container class="">
                                                    <v-row>
                                                        <v-col  cols="12" md="12" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;" >
                                                            <v-textarea height="50" outlined color="" hide-details placeholder="" error-count="" name="" label="" v-model="solicitacaodesativacao" style="margin:0px!important;padding:0px!important;" ></v-textarea> 
                                                        </v-col>
                                                    </v-row>
                                                    <v-row style="margin-top:20px;">
                                                        <v-col  cols="12" md="4" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;" >
                                                        </v-col>
                                                        <v-col  cols="12" md="8" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;display:flex;justify-content:flex-end;margin-top:20px;" >
                                                            <v-btn color="info" @click="desativarCliente()"  hide-details>Registrar e Desativar Cliente</v-btn >
                                                        </v-col>
                                                    </v-row>
                                                  
                                                   
                                                    </v-container>
                                                        
                                            </v-col>
                                            
                                            
                                        
                                        

                                        </v-row>
                                    </v-container>
                                </div>
                                
                            </div>

                          

                    </div>

    </v-dialog>

    <v-dialog v-model="exibirModalParametros" width="80vw" style="height:90vw;padding:50px;"  >

                    <div style="display:flex;flex-direction:row;width:100%;max-height:80vh;height:80vw;">
                    
                
                            <div style="width:100%;display:flex;flex-direction:column;border-right:1px solid #b1b1b1;height:100%;">

                                <div style="background-color:white;padding:50px;">
                                    
                                    <!-- {{clienteselecionado}}  -->
                                    <p style="font-size:30px;">Usuario Logado: <b> {{usuariologado}}</b></p>
                                    <p style="font-size:30px;">Parametros de Configuração {{clienteselecionado.nomesistemas}} do <b>{{clienteselecionado.nome}}</b></p>
                                    <v-container class="pa-8" style="padding-top:1px!important;background-color:white;">
                                        <v-row style="background-color:white;" v-if="listaParametros?.length > 0">

                                            <v-col   v-for="parametro in listaParametros" :key="parametro.id" style="margin-top:20px;background-color:white;" cols="12" md="12">{{parametro.chave}}
                                                    <v-container class="" style="background-color:white;">
                                                      <v-row style="display:flex;flex-direction:row;align-items:space-between;justify-content:space-between;background-color:white;">
                                                          <v-col  cols="12" md="2" style="margin-right:0px;" >
                                                              <v-textarea height="20" outlined color="" hide-details placeholder="" error-count="" name="" label="" v-model="parametro.valor" style="margin:0px!important;padding:0px!important;" ></v-textarea> 
                                                          </v-col>
                                                          <v-col  cols="12" md="2" style="margin-right:0px;" >
                                                              <v-textarea height="20" outlined color="" hide-details placeholder="" error-count="" name="" label="" v-model="parametro.valor2" style="margin:0px!important;padding:0px!important;" ></v-textarea> 
                                                          </v-col>
                                                          <v-col  cols="12" md="2" style="margin-right:0px;" >
                                                              <v-textarea height="20" outlined color="" hide-details placeholder="" error-count="" name="" label="" v-model="parametro.valor3" style="margin:0px!important;padding:0px!important;" ></v-textarea> 
                                                          </v-col>
                                                          <v-col  cols="12" md="2" style="margin-right:0px;" >
                                                              <v-textarea height="20" outlined color="" hide-details placeholder="" error-count="" name="" label="" v-model="parametro.valor4" style="margin:0px!important;padding:0px!important;" ></v-textarea> 
                                                          </v-col>
                                                          <v-col  cols="12" md="2" style="margin-right:0px;" >
                                                              <v-textarea height="20" outlined color="" hide-details placeholder="" error-count="" name="" label="" v-model="parametro.valor5" style="margin:0px!important;padding:0px!important;" ></v-textarea> 
                                                          </v-col>
                                                          <v-col  cols="12" md="2" style="margin-right:0px;" >
                                                              <v-textarea height="20" outlined color="" hide-details placeholder="" error-count="" name="" label="" v-model="parametro.valor6" style="margin:0px!important;padding:0px!important;" ></v-textarea> 
                                                          </v-col>
                                                      </v-row>
                                                      <v-row style="display:flex;flex-direction:row;align-items:flex-end;justify-content:flex-end;margin-top:20px;">
                                                        <v-btn color="info" @click="salvarParametro(parametro)"  hide-details>Salvar</v-btn >
                                                      </v-row>
                                                    </v-container>
                                            </v-col>

<!-- 
                                            <v-col cols="12" md="12">Registre a solicitação de desativação aqui (Email, whatsapp, solicitação)
                                                    <v-container class="">
                                                    <v-row>
                                                        <v-col  cols="12" md="12" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;" >
                                                            <v-textarea height="50" outlined color="" hide-details placeholder="" error-count="" name="" label="" v-model="solicitacaodesativacao" style="margin:0px!important;padding:0px!important;" ></v-textarea> 
                                                        </v-col>
                                                    </v-row>
                                                    <v-row style="margin-top:20px;">
                                                        <v-col  cols="12" md="4" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;" >
                                                        </v-col>
                                                        <v-col  cols="12" md="8" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;display:flex;justify-content:flex-end;margin-top:20px;" >
                                                            <v-btn color="info" @click="desativarCliente()"  hide-details>Registrar e Desativar Cliente</v-btn >
                                                        </v-col>
                                                    </v-row>
                                                  
                                                   
                                                    </v-container>
                                                        
                                            </v-col> -->
                                            
                                            
                                        
                                        

                                        </v-row>
                                    </v-container>
                                </div>
                                
                            </div>

                          

                    </div>

    </v-dialog>

    <v-dialog v-model="exibirModalPausar" width="50vw" style="max-height:90vh;height:90vw;padding:50px;"  >

                    <div style="display:flex;flex-direction:row;width:100%;max-height:80vh;height:80vw;">
                    
                
                            <div style="padding:50px;background-color:white;width:100%;display:flex;flex-direction:column;border-right:1px solid #b1b1b1;max-height:80vh;height:80vw;">

                                <div>
                                    <v-container class="pa-8" style="padding-top:1px!important;">
                                      <!-- {{clienteselecionado}}  -->
                                        <p style="font-size:30px;">Usuario Logado: <b> {{usuariologado}}</b></p>
                                        <p style="font-size:30px;"><span style="color:red;"><b>Pausar</b></span> {{clienteselecionado.nomesistemas}} do <b>{{clienteselecionado.nome}}</b></p>
                                        <p style="font-size:18px;color:red"><b>Observação</b>: Pausar uma implantação impactará somente na listagem de clientes realmente ativos para faturamento. Não irá pausar nenhum serviço do cliente</p>
                                        <v-row>



                                            <v-col cols="12" md="12" style="margin-top:20px;">Registre a solicitação de Pausa aqui (Email, whatsapp, solicitação)
                                                    <v-container class="">
                                                    <v-row>
                                                        <v-col  cols="12" md="12" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;" >
                                                            <v-textarea height="50" outlined color="" hide-details placeholder="" error-count="" name="" label="" v-model="solicitacaopausa" style="margin:0px!important;padding:0px!important;" ></v-textarea> 
                                                        </v-col>
                                                    </v-row>
                                                    <v-row style="margin-top:20px;">
                                                        <v-col  cols="12" md="4" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;" >
                                                        </v-col>
                                                        <v-col  cols="12" md="8" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;display:flex;justify-content:flex-end;margin-top:20px;" >
                                                            <v-btn color="info" @click="pausarCliente()"  hide-details>Registrar e Desativar Cliente</v-btn >
                                                        </v-col>
                                                    </v-row>
                                                  
                                                   
                                                    </v-container>
                                                        
                                            </v-col>
                                            
                                            
                                        
                                        

                                        </v-row>
                                    </v-container>
                                </div>
                                
                            </div>

                          

                    </div>

    </v-dialog>

    <v-dialog v-model="exibirModalRobo" width="90vw" hiehgt="90vw" style="max-height:90vh;height:90vw;padding:10px;"  >

                    <div style="display:flex;flex-direction:row;width:100%;max-height:90vh;height:90vw;">
                    
                        <!-- {{clienteselecionado}} -->
                            <div style="padding:30px;background-color:white;width:100%;display:flex;flex-direction:column;border-right:1px solid #b1b1b1;max-height:90vh;height:90vw;">

                                <div>
                                    <v-container class="" style="padding-top:1px!important;">
                                      
                                        <v-row>

                                            <v-col cols="12" md="6" style="margin-top:0px;">

                                                    <v-container class="">
<!-- {{aplicacaoSuporte}} <br>
--{{arquivoAppSuporte}}-- <br> -->
                                                        <v-row>
                                                            <v-col  cols="12" md="12" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;" >
                                                                <p style="font-size:22px;">Usuario Logado: <b> {{usuariologado}}</b></p>
                                                                <p style="font-size:22px;"><span style="color:red;"><b>Robôs e outras Aplicações de Apoio</b></span> {{clienteselecionado.nomesistemas}} do <b>{{clienteselecionado.nome}}</b></p>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row style="padding-top:10px;padding-bottom:10px;">
                                                            <v-col  cols="12" md="4" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;" >
                                                                <v-text-field outlined hide-details label="Titulo" v-model="aplicacaoSuporte.titulo"></v-text-field>
                                                            </v-col>
                                                            <v-col  cols="12" md="8" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;" >
                                                                <v-file-input @change="onFileChangeAppSuporte" chips accept=".zip,.rar,.7zip" label="Adicione o Arquivo Zipado" id="arquivozipado" name="arquivozipado"></v-file-input>
                                                            </v-col>
                                                        </v-row>

                                                        <v-row style="padding-top:10px;padding-bottom:10px;">
                                                            <v-col  cols="12" md="12" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;" >
                                                                <v-text-field outlined hide-details label="Localização da aplicação no Cliente" v-model="aplicacaoSuporte.localizacao"></v-text-field>
                                                            </v-col>
                                                        </v-row>

                                                        <v-row style="padding-top:10px;padding-bottom:10px;">
                                                            <v-col  cols="12" md="12" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;" >
                                                                <v-text-field outlined hide-details label="Id Anydesk" v-model="aplicacaoSuporte.acessoremoto"></v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                      
                                                        <v-row style="padding-top:0px;padding-bottom:0px;">
                                                            <v-col  cols="12" md="12" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;" >
                                                                <v-textarea height="50" outlined hide-details label="Descrição do uso" v-model="aplicacaoSuporte.descricao" style="margin:0px!important;padding:0px!important;" ></v-textarea> 
                                                            </v-col>
                                                        </v-row>
                                                        
                                                        <v-row style="margin-top:20px;">
                                                            <v-col  cols="12" md="4" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;" >
                                                            </v-col>
                                                            <v-col  cols="12" md="8" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;display:flex;justify-content:flex-end;margin-top:20px;" >
                                                                <v-btn color="info" @click="salvarAplicacaoSuporte()"  hide-details>Salvar</v-btn >
                                                            </v-col>
                                                        </v-row>
                                                      
                                                   
                                                    </v-container>
                                                        
                                            </v-col>

                                            <v-col cols="12" md="6" style="margin-top:0px;">
                                                <div style="height:100%;max-height:80vh;overflow:auto;">
                                                  <v-data-table :items="listaAppSuporte" :headers="headersAppSuporte" :items-per-page="50">

                                                        <template v-slot:[`item.url_arquivo`]="{ item }">
                                                          <div style="padding:15px;">
                                                            <div><b style="margin-right:10px;">Titulo:</b>{{item.titulo}}</div>
                                                            <div><b style="margin-right:10px;">Descrição:</b>{{item.descricao}}</div>
                                                            <div><b style="margin-right:10px;">Local:</b>{{item.localizacao}}</div>
                                                            <div><b style="margin-right:10px;">Acesso:</b>{{item.acesso_remoto}}</div>
                                                            <div><b style="margin-right:10px;">Dt:</b>{{item.data_formatada}}</div>
                                                            <div>
                                                              <a target='_blank' 
                                                                :href="urlAPI+item.url_arquivo">Download</a>
                                                            </div>
                                                          </div>
                                                        </template>

                                                        <template v-slot:[`item.titulo`]="{ item }">
                                                                <v-btn small color="red" @click="desativarAplicacaoSuporte(item)">
                                                                  <v-icon color="#f9f9f2" size='28'>mdi-delete</v-icon>
                                                                </v-btn>
                                                            
                                                        </template>


                                                  </v-data-table>
                                                </div>
                                            </v-col>
                                            
                                            
                                        
                                        

                                        </v-row>
                                    </v-container>
                                </div>
                                
                            </div>

                          

                    </div>

    </v-dialog>


  </v-container>
</template>

<script>
import {alertar} from '@/services/notificacao.service.js';
import ClienteServico from '@/services/cliente.service.js';
import ListSkeleton from '@/components/Loading/ListSkeleton.vue';
import sistemasCliente from '@/services/sistemasCliente.service.js';
import SistemaServico from '@/services/sistema.service.js';
import AtividadeServico from '@/services/atividade.service.js'
import SuporteServico from '@/services/suporte.service.js'
import dayjs from 'dayjs'
import JsonCSV from 'vue-json-csv'

export default {
  components: {ListSkeleton,JsonCSV},
  data(){
    return{
      urlAPI:null,
      usuariologado:null,
      aplicacaoSuporte: {
        id:null,
        titulo:null,
        arquivozipado:null,
        localizacao:null,
        acessoremoto:null,
        descricao:null,
      },
      arquivoAppSuporte:null,
      listaAppSuporte:[],
      headersAppSuporte:[
        { text: 'Id', align: 'start', sortable: false, value: 'id', width:'10%' },
        { text: 'arquivo', value: 'url_arquivo', width:'80%' },
        { text: 'Desativar', value: 'titulo', width:'10%' },
        // { text: 'Descrição', value: 'descricao' },
        // { text: 'Localização', value: 'localizacao' },
        // { text: 'Acesso', value: 'acesso_remoto' },
        // { text: 'Dt', value: 'data_criacao' },
      ],
      removeudoservidor:null,
      solicitacaodesativacao:null,
      solicitacaopausa:null,
      movimento:{idatividade:null,datahoramovimento:null,descricao:null,idusuario:null,tipomovimento:null,tempo_gasto:null},
      inputNome: '',
      SistemasDoCliente: [],
      sistemaSelecionado: {nome:'Ficha Digital',valor:1},
      sistemas: [
        {nome:'Todos',valor:0},
        // {nome:'Ficha Digital',valor:1},
        // {nome:'Cardápio Digital',valor:2},
        // {nome:'FD Guest',valor:3},
        // {nome:'E-Spot',valor:4},
        // {nome:'Check-out Express',valor:30},
        // {nome:'Check-in Pay',valor:31},
      ],
      listaParametros:[],
      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Ações', value: 'action' },
        { text: 'URL', value: 'urlficha'},
        { text: 'Pausado?', value: 'clientepausado'},
        { text: 'Ativo', value: 'ativo'},
        { text: 'Teste', value: 'teste'},
        { text: 'Nome', value: 'nome',width:'150px' },
        { text: 'CNPJ', value: 'cnpj' },
        { text: 'Sistema', value: 'nomesistemas',width:'150px' },
        { text: 'Integracao', value: 'tipointegracao' },
        { text: 'Email 1', value: 'contato1'},
        { text: 'Fone 1', value: 'telefonecontato1',width:'150px'},
        { text: 'Email 2', value: 'contato2'},
        { text: 'Fone 2', value: 'telefonecontato2',width:'150px'},
      ],
      items: [],
      filtroPorSistema: [],
      itemsParaFiltros: [],
      loading: false,
      status:[
        {nome:'Todos',valor:0},
        {nome:'Ativo',valor:true},
        {nome:'Desativado',valor:false}
      ],
      statusSelecionado:{nome:'Ativo',valor:true},
      pausados:[
        {nome:'Todos',valor:0},
        {nome:'Sim',valor:true},
        {nome:'Não',valor:false}
      ],
      pausadoSelecionado:{nome:'Não',valor:false},
      tipointegracao:[
        {nome:'Todos',valor:0},
        {nome:'Totvs Oracle',valor:1},
        {nome:'Totvs Sql Server',valor:2},
        {nome:'Hits',valor:3},
        {nome:'ERBON',valor:4},
        {nome:'ADMH',valor:5},
        {nome:'NEW HOTEL',valor:6},
      ],
      tipointegracaoSelecionado:{nome:'Totvs Oracle',valor:1},
      exibirModalMovimento:false,
      exibirModalDesativar:false,
      exibirModalParametros:false,
      exibirModalPausar:false,
      exibirModalRobo:false,
      movimentos:[],
      clienteselecionado:{},
      listateste:[
        {nome:'Todos',valor:0},
        {nome:'Não',valor:1},
        {nome:'Sim',valor:2},
      ],
      tipoteste:{nome:'Todos',valor:0},
    }
  },
  methods:{
    async abrirEdicao(item){
      window.open(`/lista/cliente/${item.id}`,'_BLANK')
    },
    async desativarCliente(){
      
      // alert(this.removeudoservidor.trim() != '')
      if(!this.removeudoservidor && this.removeudoservidor?.trim() != ''){
        alert('informe se removeu do Servidor o sistema')
        return
      }

      if(!this.solicitacaodesativacao && this.solicitacaodesativacao?.trim() != ''){
        alert('informe a solicitação, o texto da solicitação e quem solicitou')
        return
      }

      await ClienteServico.desativarCliente({dados:this.clienteselecionado,
                                              removeudoservidor:this.removeudoservidor,
                                              solicitacaodesativacao:this.solicitacaodesativacao}).then(async () => {
                                                  alert(`Implantação do ${this.clienteselecionado.nomesistemas} do Cliente ${this.clienteselecionado.nome} foi Desativado.`)
                                                  this.removeudoservidor = null
                                                  this.solicitacaodesativacao = null
                                                  this.exibirModalDesativar = false
                                                  this.requisitarClientes()
                                              }).catch(error =>{
                                                  alert(error)
                                              })
    },

    onFileChangeAppSuporte(event){
      
        console.log('event ',event)
        // this.arquivoAppSuporte = event.target.files[0];
        this.arquivoAppSuporte = event;
        console.log('arquivoAppSuporte ',this.arquivoAppSuporte)
    },


    async desativarAplicacaoSuporte(item){
         await SuporteServico.desativarAplicacaoSuporte(item)
         .then(async () => {
             this.buscarAppsSuporte(this.clienteselecionado)
         }).catch(error=>{
              alert('Não foi Possível Desativar a Aplicação Suporte!')
              console.log(error)
              this.exibirModalCriarAtividade=false
          })
    },

    async salvarAplicacaoSuporte(){


        if(!this.aplicacaoSuporte.titulo && this.aplicacaoSuporte.titulo?.trim() != ''){
            alert('Informe um Titulo Valido!')
            return
        }

        if(!this.aplicacaoSuporte.localizacao && this.aplicacaoSuporte.localizacao?.trim() != ''){
            alert('Informe uma Localizacao Valido!')
            return
        }

        if(!this.aplicacaoSuporte.acessoremoto && this.aplicacaoSuporte.acessoremoto?.trim() != ''){
            alert('Informe uma Forma de Acesso Remoto Valido!')
            return
        }

        if(!this.aplicacaoSuporte.descricao && this.aplicacaoSuporte.descricao?.trim() != ''){
            alert('Informe uma Descricao Valido!')
            return
        }

        if(!this.arquivoAppSuporte ){
            alert('Informe um Arquivo Zipado Valido!')
            return
        }


        this.aplicacaoSuporte.id_usuario = localStorage.getItem('idusuario');
        this.aplicacaoSuporte.id_cliente = this.clienteselecionado.id
        this.aplicacaoSuporte.id_clientesistema = this.clienteselecionado.idclientesistema

        

        await SuporteServico.registrarAplicacaoSuporte(this.aplicacaoSuporte).then(async (resp) => {

                                  console.log('resp ',resp)
                                  this.aplicacaoSuporte.id = resp.data.id
                                              
                                          let formData = new FormData();
                                          console.log(' this.arquivoAppSuporte.name ', this.arquivoAppSuporte.name)
                                          formData.append('file', this.arquivoAppSuporte, this.arquivoAppSuporte.name);
                                          formData.append('idaplicacaosuporte', this.aplicacaoSuporte.id);
                                          formData.append('id_cliente', this.aplicacaoSuporte.id_cliente);
                                          formData.append('id_cliente_sistemas', this.aplicacaoSuporte.id_clientesistema);
                                          console.log("vai mandar pro upload")

                                          let dados = {
                                            formData:formData,
                                            params:this.aplicacaoSuporte.id_cliente+'/'+this.aplicacaoSuporte.id_clientesistema+'/'+this.aplicacaoSuporte.id,
                                          }
                                          SuporteServico.uploadAplicacaoSuporte(dados)
                                            .then(() =>{
                                                alert("Registro e Upload realizado!")
                                                this.aplicacaoSuporte = {
                                                                        id:null,
                                                                        titulo:null,
                                                                        arquivozipado:null,
                                                                        localizacao:null,
                                                                        acessoremoto:null,
                                                                        descricao:null,
                                                                      }
                                                this.arquivoAppSuporte = null
                                                this.buscarAppsSuporte(this.clienteselecionado)
                                            })
                                            .catch(error =>{
                                                alert("Não foi possivel")
                                                console.log(error)
                                            })

                                          


                              }).catch(error=>{
                                  alert('Não foi Possível Registrar!6')
                                  console.log(error)
                                  this.exibirModalCriarAtividade=false
                              })

        

    },

    async pausarCliente(){
      

      if(!this.solicitacaopausa && this.solicitacaopausa?.trim() != ''){
        alert('informe a solicitação, o texto da solicitação e quem solicitou')
        return
      }

      await ClienteServico.PausarCliente({dados:this.clienteselecionado,
                                              solicitacaopausa:this.solicitacaopausa}).then(async () => {
                                                  alert(`Implantação do ${this.clienteselecionado.nomesistemas} do Cliente ${this.clienteselecionado.nome} foi Pausada.`)
                                                  this.solicitacaopausa = null
                                                  this.exibirModalPausar = false
                                                  this.requisitarClientes()
                                              }).catch(error =>{
                                                  alert(error)
                                              })
    },
    async registrarMovimento(tipo){

        if(this.movimento.descricao){

            this.movimento.idusuario = localStorage.getItem('idusuario');
            this.movimento.tipomovimento = tipo;
            this.movimento.idcliente = this.clienteselecionado.id
            this.movimento.datahoramovimento = dayjs().format()

            await AtividadeServico.registrarMovimentoPorCliente(this.movimento).then(async () => { 
                this.movimento = {idatividade:null,datahoramovimento:null,descricao:null,idusuario:null,tipomovimento:null,tempo_gasto:null}
                this.buscarMovimentosPorCliente({id:this.clienteselecionado.id})
            }).catch(error=>{
                alert('Não foi Possível Registrar!6')
                console.log(error)
                this.exibirModalCriarAtividade=false
            })
        }else{
            alert("Titulo Obrigatório!")
        }

    },
    async buscarMovimentosPorCliente (cliente){


            await AtividadeServico.buscarMovimentoPorCliente({idcliente:cliente.id}).then(async (resp) => { 

                let movimentos = resp.data
                movimentos = movimentos.sort(function (a, b) {
                    if (a.datahoramovimento > b.datahoramovimento) {
                        return -1;
                    }
                    if (a.datahoramovimento < b.datahoramovimento) {
                        return 1;
                    }
                    return 0;
                })
                this.movimentos = movimentos

            }).catch(error=>{
                alert('Não foi Possível Registrar!4')
                console.log(error)
            })

    },
    setModalMovimentos(item){
      this.exibirModalMovimento = !this.exibirModalMovimento
      if(this.exibirModalMovimento){
        this.clienteselecionado = item
        this.buscarMovimentosPorCliente(item)
      }
    },
    setModalDesativar(item){
      this.exibirModalDesativar = !this.exibirModalDesativar
      if(this.exibirModalDesativar){
        this.clienteselecionado = item
        // this.desativarCliente(item)
      }
    },
    async setModalParametros(item){
      console.log('a 1')
      this.listaParametros = []
      this.clienteselecionado = item
      if(!this.exibirModalParametros){
        console.log('a 2.1')
        await this.buscarParametros()
        console.log('a 2.2')
      }
      console.log('a 3')
      this.exibirModalParametros = !this.exibirModalParametros
      // if(this.exibirModalParametros){
      //   this.clienteselecionado = item
      //   // this.buscarParametros()
      // }
    },
    async buscarParametros(){
      this.listaParametros = await ClienteServico.buscarParametros(this.clienteselecionado).then(res=> res.data)
    },
    async salvarParametro(parametro){
      await ClienteServico.salvarParametro(parametro).then(()=> {
                alertar('success', '=D', 'Salvo com sucesso!')
            }).catch(error =>{
                console.log(error)
                alertar('warning', '=(', 'Não foi possível Salvar!')
            })
    },
    setModalPausar(item){
      this.exibirModalPausar = !this.exibirModalPausar
      if(this.exibirModalPausar){
        this.clienteselecionado = item
      }
    },
    setModalRobo(item){
      this.exibirModalRobo = !this.exibirModalRobo
      if(this.exibirModalRobo){
        this.clienteselecionado = item
        this.buscarAppsSuporte(this.clienteselecionado)
      }
    },
    async buscarAppsSuporte(cliente){
      this.listaAppSuporte = []
      this.listaAppSuporte = await SuporteServico.buscarAppsSuporte(cliente).then(res=> res.data)
    },
    async requisitarClientes(){

      // console.log('tipointegracao:this.tipointegracao ',this.tipointegracaoSelecionado)
      // console.log('statusSelecionado:this.statusSelecionado ',this.statusSelecionado)
      console.log('sistemaSelecionado:this.sistemaSelecionado ',this.sistemaSelecionado)
      let objetofiltro = {
        tipointegracao:this.tipointegracaoSelecionado.valor,
        ativo:this.statusSelecionado.valor,
        pausado:this.pausadoSelecionado.valor,
        sistema:this.sistemaSelecionado.valor,
        nome:this.inputNome,
        teste:this.tipoteste.valor,
      }
      
      this.items = await ClienteServico.buscar(objetofiltro).then(res=> res.data)
      this.itemsParaFiltros = await ClienteServico.buscar(objetofiltro).then(res=> res.data)
      for(let i = 0 ; i < this.items.length; i++){
        if(this.items[i].ativo == true){
          this.items[i].ativo = "Sim"
          this.itemsParaFiltros[i].ativo = "Sim"
        }else{
          this.items[i].ativo = "Não"
          this.itemsParaFiltros[i].ativo = "Não"
        }
      }
    },

    async requisitarSistemasDoCliente(){
      const buscaSistemas = await sistemasCliente.buscar({id: this.id}).then(res=> res)
      this.SistemasDoCliente = buscaSistemas.data;
     
    },

    addFiltroSistema() {
      if(this.sistemaSelecionado.length === 0) {
        return alertar('warning', '', 'Selecione um sistema')
      }
      const filter = this.SistemasDoCliente.filter((cliente) => cliente.nome_sistema.toLowerCase().includes(this.sistemaSelecionado.toLowerCase()));
      let filtersObj = [];
      filter.forEach((cliente) => {
        const obj = {
        id: cliente.id_cliente,
        nome: cliente.nome_cliente,
        contato1: cliente.contato1,
        contato2: cliente.contato2,
        telefonecontato1: cliente.telefonecontato1,
        telefonecontato2: cliente.telefonecontato2,
      }
      filtersObj = [...filtersObj, obj];
      })
      this.filtroPorSistema = filtersObj;
      this.items = filtersObj;
    },

    limparFiltroSistema() {
      this.items = this.itemsParaFiltros;
      this.inputNome = "";
      this.sistemaSelecionado = {nome:'Todos',valor:0}
      this.statusSelecionado = {nome:'Ativo',valor:true}
      this.tipointegracaoSelecionado ={nome:'Todos',valor:0}
    },


    async requisitarSistemas(){
      const buscaSistemas = await SistemaServico.buscar().then(res=> res.data)
      console.log(buscaSistemas)
      buscaSistemas.forEach((sistema) => {
        this.sistemas = [...this.sistemas, {valor:sistema.id,nome:sistema.nome}];
      });
      return buscaSistemas;
    },


    filtroPesquisaNome() {
      if (this.filtroPorSistema.length > 0) {
        const filter = this.filtroPorSistema.filter((cliente) => cliente.nome.toLowerCase().includes(this.inputNome.toLowerCase()));
        this.items = filter;
      } else {
        const filter = this.itemsParaFiltros.filter((cliente) => cliente.nome.toLowerCase().includes(this.inputNome.toLowerCase()));
        this.items = filter;
      }
     
    }
  },
  async mounted(){
    this.usuariologado = localStorage.getItem('nome')
    await this.requisitarClientes()
    await this.requisitarSistemasDoCliente()
    await this.requisitarSistemas()
    this.loading = true
    this.urlAPI = (process.env.NODE_ENV === 'development') ? 'http://localhost:3343/' : 'https://apies.economysoftware.com.br/'
  }
}
</script>

<style lang="scss" scoped>
.header{
  display: flex;
  padding: 1em;
  margin: 0 auto;
  width: 80vw;
  max-width: 100%;

  >h3{
    letter-spacing: 0.02em;
  }
  margin: 1em auto;
  background: rgba(215,211,222,0.5);
  height: fit-content;
  border-radius: 10px;
  >span{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.section-header{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1em 0;
}

.section-nav{
  display: flex;
  align-items: center;
  justify-content: center;

  ul{
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #eff3e7;
    padding: 1em;
    width: 100%;

    li{
      margin: 0 .5em;
      padding: 1em 1.5em;
      border-radius: 50px;
      background: #eff3e7;
  box-shadow:  4px 4px 10px #d9d9d9,
             4px 4px 10px #ffffff;
    }
  }
}

.wrapper-list{
  margin: 0 auto;
  width: 80vw;
  max-width: 100%;
  @media screen and (max-width: 768px) {
    width: 90vw;    
  }
}

.btn{
      padding: .75em 1em;
      line-height: 1.5;
      color: white;
      font-weight: bold;
      letter-spacing: 0.02em;
      background: #1377f2;
      border-radius: 10px;
      text-transform: capitalize;
  }

.grow{
  flex: 1;
}

.containerFiltros {
  // display: flex;
  // justify-content: flex-start;
  flex-direction: column;
  // align-items: center;
  // margin-bottom: 20px;
  // width: 70vw;
  // margin: auto;
  // background-color: red;
  // // margin-bottom: 20px;
  display: flex;
  padding: 1em;
  margin: 0 auto;
  width: 80vw;
  max-width: 100%;

  
  margin: 1em auto;

  height: fit-content;
  border-radius: 10px;

}

.inputPesquisa {
  background-color: white;
  margin: 0 auto;
  height: 50px;
  padding: 15px;
  border-radius: 8px;
  width: 80vw;
  max-width: 100%;
  @media screen and (max-width: 768px) {
    width: 90vw;    
  }
}

.containerFiltroSistema {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.select {
  width: 100%;
  margin-top: 20px;
}

.btnAddFiltro {
  background-color: #1377f2;
  border-radius: 5px;
  width: 200px;
  height: 30px;
  cursor: pointer;
  color: white;
}

.btnLimarFiltro {
  background-color: #fb8c00;
  border-radius: 5px;
  width: 150px;
  height: 30px;
  cursor: pointer;
  color: white;
  margin-left: 10px;
}

</style>